.faq{
    width: 90vw;
    margin: auto;
    div{
        background-color: #deb8b8;
        padding: 10px;
        margin-bottom: 10px;
    }
    h1{
        font-weight: 400;
        font-size: 50px;
    }
}