@import './Mixin.scss';

.footer{
    background-color: black;
    color: white;
    padding: 20px 0;
    display: flex;
    text-align: start;
    justify-content: space-around;
    margin-top: 15px;
    .leftFooter{
        display: flex;
        flex-direction: column;
    }
    .rightFooter{
        display: flex;
        flex-direction: column;
    }
    h1{
        margin: 0;
        padding: 0;
        font-weight: 400;
    }
    img{
        height: 40px;
        color: white;
    }
    a{
        color: white;
        margin-top: 10px;
    }
    @include mQ(750px){
        display: flex;
        flex-direction: column;
        text-align: center;
        p{
            margin: 10px 0;
        }
    }
}
