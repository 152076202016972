@import './Mixin.scss';

.recentWork{
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
    margin: auto;
    img{
        max-width: calc(40vw - 30px);
        border: 15px solid #deb8b8;
        box-shadow: 0 0 10px #deb8b8;
        border-radius: 10px;
        justify-self: center;
    }
    @include mQ(1150px){
        img{
            max-width: calc(30vw);
        }
    }
}