@import './Mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;500&display=swap');


.contactMe{
    width: 100vw;
    form{
        background-color: #deb8b8;
        text-align: left;
        width: 80vw;
        margin: auto;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0px 0px 10px #deb8b8;
        display: flex;
        flex-direction: column;
        .inputItems{
            display: flex;
            flex-direction: column;
            h3{
                font-size: 23px;
                font-weight: 100;
                margin: 0;
                padding: 0;
                margin-top: 10px;
            }
        }
        button{
            width: 30%;
            margin: auto;
            margin-top: 20px;
            font-size: 23px;
            background-color: white;
            border: 1px solid rgb(167, 110, 164);
        }
        textarea{
            min-height: 100px;
            width: calc(80vw - 6px);
        }
        input{
            font-family: 'Cormorant Garamond';
            font-size: 23px;
            font-weight: bold;
            border: none;
            border-bottom: 1px solid black;
        }
        select{
            font-family: 'Cormorant Garamond';
            font-size: 23px;
            font-weight: bold;
            border: none;
            border-bottom: 1px solid black;
        }
        option{
            font-weight: bold;
        }
        @include mQ(1150px){
            .inputItems{
                h3{
                    font-size: 18px;
                }
            }
            input{
                font-size: 18px;
            }
            select{
                font-size: 18px;
            }
            button{
                font-size: 15px;
            }
        }
    }
}