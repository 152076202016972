@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;500&display=swap');
@import 'scss/Mixin.scss';

hr{
  border: 1px solid #deb8b8;
  box-shadow: 0 0 10px #deb8b8;
}

.header{
  z-index: 1;
  border-bottom: 1px solid #deb8b8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #e0e0db;
  width: 100vw;
  padding-top: 15px;
  a{
    margin: 0px 10px;
    padding: 10px 0;
    font-family: 'Cormorant Garamond';
    font-size: 40px;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.4em;
    color: black;
    &:active{
      color: grey;
    }
    &:visited{
      color: black;
    }
    &:hover{
      text-decoration: underline;
      color: #deb8b8;
    }
  }
  .desktopHeader{
    display: flex;
    align-items: center;
    grid-gap: 16px;
    margin-right: 50px;
    a{
      font-size: 30px;
      padding: 0;
      margin: 0;
    }
    img{
      height: 40px;
      margin-top: 13px;
    }
  }
  .mobileHeader{
    display: none;
  }
  @include mQ(1150px){
    padding: 10px 0;
    .desktopHeader{
      display: none;
    }
    .mobileHeader{
      display: flex;
      width: 100vw;
      justify-content: space-around;
      align-items: center;
    }
    .mobileHide{
      display: none;
    }
    .linkHome{
      display: none;
    }
    .mobileShow{
      background-color: #e0e0db;
      width: 100vw;
      height: calc(100vh - 67px);
      position: fixed;
      top: 66px;
      left: 0;
      overflow: auto;
    }
    img{
      height: 50px;
      margin-top: 50px;
      &:hover{
        cursor: pointer;
      }
    }
    .linkHomeMobile{
      a{
        font-size: 40px;
        font-weight: 500;
        margin: 0;
        padding: 0;
      }
    }
    .insideMobileHeader{
      width: 100vw;
      top: 61px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 120px; 
      h3{
        margin: 20px 0;
        font-weight: 400;
      }
    }
  }
}

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 60px;
  height: 45px;
  position: relative;
  margin-left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
display: block;
position: absolute;
height: 5px;
width: 50%;
background: black;
opacity: 1;
-webkit-transform: rotate(0deg);
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .25s ease-in-out;
-moz-transition: .25s ease-in-out;
-o-transition: .25s ease-in-out;
transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
left: 50%;
border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
left:0px;
border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
top: 18px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
top: 36px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
-webkit-transform: rotate(-45deg);
-moz-transform: rotate(-45deg);
-o-transform: rotate(-45deg);
transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
left: 5px;
top: 7px;
}

#nav-icon2.open span:nth-child(2) {
left: calc(50% - 5px);
top: 7px;
}

#nav-icon2.open span:nth-child(3) {
left: -50%;
opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
left: 100%;
opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
left: 5px;
top: 29px;
}

#nav-icon2.open span:nth-child(6) {
left: calc(50% - 5px);
top: 29px;
}
