@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;500&display=swap');
@import './Mixin.scss';

.homeComponent{
    display: flex;
    width: 100vw;
    justify-content: space-around;
    grid-gap: 10px;
    font-family: 'Cormorant Garamond';
    align-items: center;
    margin-top: 80px;
    img{
        max-height: 70vh;
    }
    .rightHome{
        max-width: 40vw;
        p{
            font-size: 50px;
            margin: 0;
            padding: 0;

        }
        h1{
            font-weight: 500;
            font-size: 100px;
            margin: 0;
            padding: 0;
        }
    }
    @include mQ(1150px){
        flex-direction: column;
        align-items: center;
        grid-gap: 50px;
        img{
            max-height: 70vh;
            max-width: 90vw;
        }
        .rightHome{
            max-width: 90vw;
            h1{
                font-size: 40px;
            }
            p{
                font-size: 40px;
            }
        }
    }
}

.downArrow{
    margin-top: 40px;
    max-width: 60vw;
    max-height: 80vh;
    width: 150px;
    @include mQ(1150px){
        width: 100px;
    }
}