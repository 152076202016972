@import './Mixin.scss';

.prices{
    width: 90vw;
    display: grid;
    justify-content: center;
    margin: auto;
    h1{
        font-size: 60px;
        font-weight: 400;
        padding: 0;
        margin: 15px 0;
    }
    p{
        font-size: 40px;
        padding: 0;
        margin: 15px 0;
    }
    .packagesContainer{
        background-color: #deb8b8;
        border-radius: 10px;
        max-width: 90vw;
        font-size: 40px;
        padding: 10px;
        margin-bottom: 50px;
        h1{
            font-size: 40px;
        }
        .package{
            background-color: rgba(0,0,0,0.8);
            color: #deb8b8;
            padding: 5px;
            margin: 20px;
            font-size: 30px;
            border-radius: 10px;
        }
    }
    @include mQ(750px){
        h1{
            font-weight: 400;
            font-size: 50px;
        }
        p{
            font-size: 25px;
        }
        .packagesContainer{
            font-size: 25px;
            .package{
                font-size: 20px;
            }
        }
    }
}